.date.react-datepicker__month-container,
.react-datepicker {
  width: 100% !important;
  border: 0 !important;
}

.date .react-datepicker__header {
  background-color: #fff !important;
}

.date .react-datepicker__week,
.react-datepicker__day-names {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 14px !important;
  font-weight: bold;

  .react-datepicker__day-name {
    color: #afb6cf !important;
    text-transform: uppercase !important;
  }

  .react-datepicker__day {
    color: #636e95 !important;
  }

  .react-datepicker__day.react-datepicker__day--keyboard-selected,
  .react-datepicker__day--in-range {
    color: #fff !important;
  }
}

.date .react-datepicker__header {
  border-color: #eaedf7 !important;
  border-width: 2px !important;
  padding: 10px !important;
}

.date .react-datepicker__current-month {
  margin-bottom: 20px !important;
  font-size: 25px;
  font-weight: bold;
  color: #162a3d;
  margin-top: -15px;
}
