@import "../../../assets/styles/colors.scss";

.wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #f6f6f7;
}

.sideBar {
  position: relative;
  width: 280px;
  min-width: 280px;
  max-width: 23vw;
  min-height: 100vh;
  background-color: $white;
  transition: 0.6s ease-in;

  button {
    transition: 0.6s ease-in;
    height: 45px;
  }
}

.collapseSideBar {
  @extend .sideBar;
  width: 82px;
  min-width: 82px;
  max-width: 82px;
}

.header {
  height: 55px;
  border-width: 2px !important;
}

.btnFont {
  font-size: 14px !important;
}

.wrapperWidth {
  position: relative;
  width: calc(100vw - 280px);
  max-width: calc(100vw - 280px);
  transition: 0.6s ease-in;
}

.wrapperMaxWidth {
  @extend .wrapperWidth;
  width: calc(100vw - 82px);
  max-width: calc(100vw - 82px);
}
