@import "assets/styles/colors.scss";

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  cursor: pointer;
  color: #1898e3;
  text-decoration: underline;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-input {
  border-radius: 4px;
  border: none;
  padding: 10px;
  border-radius: 3px;

  &::placeholder {
    color: #9d9e9f;
    font-size: 14px;
  }
}

.truncate {
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clear_btn {
  position: absolute;
  right: 30px;
  top: 26px;
  z-index: 10;
  cursor: pointer;
}

.nav-link.inactive {
  color: #9d9e9f;
}

.nav-item {
  font-weight: 500;
  .menu.active {
    color: #495968;
    border-bottom: 4px solid #1898e3;
  }
}

.polygon-map {
  height: 100%;
  width: 100%;
}

input.app_slider[type="checkbox"] {
  visibility: hidden;
  &:checked + label {
    background-color: $success;
    &:before {
      transform: translateX(31px);
      background-color: #fff;
    }
  }
}

input.app_slider + label {
  display: flex;
  width: 60px;
  height: 30px;
  border: transparent 6px solid;
  border-radius: 99em;
  position: relative;
  transition: transform 0.25s ease-in-out;
  transform-origin: 50% 50%;
  cursor: pointer;
  background-color: $danger;
  &:before {
    transition: transform 0.25s ease;
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: $white;
    border-radius: 50%;
    top: -1px;
    left: -1px;
  }
}

input {
  border: 1px solid #cfd8dc !important;
}

input::placeholder {
  color: #95a7b7 !important;
}

thead {
  border-top-style: hidden !important;
}

.cards {
  min-height: fit-content;
  padding-bottom: 30px;

  p,
  button,
  input,
  select {
    font-size: 14px;
  }
}
.bg-black {
  background-color: #020202;
}
.error-msg {
  width: 100%;
  height: 52px;
  background-color: #ff5f5f;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  padding: 20px;
  z-index: 100;
  max-width: 1000px !important;
  #close {
    cursor: pointer;
    filter: invert(100%);
  }
}

.modal-dialog {
  &:lang(ar) {
    direction: rtl;
  }
  &:lang(en) {
    direction: ltr;
  }
}

  @media (min-width: 1400px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl{
      max-width: calc(90vw - 279.25px) !important;
    }
  }
