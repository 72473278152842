.confirmMessage {
  .modalContent {
    max-width: 535px;
    width: 100%;
    min-height: 350px;
  }

  .modalBody {
    padding: 20px;
    margin: auto;
  }
}
