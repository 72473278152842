@import "./colors.scss";

body {
  font-family: "Roboto", sans-serif;
}

button {
  &:focus {
    box-shadow: none !important;
  }
}

.btn-primary {
  color: $white !important;
}

.btn-primary-light {
  background-color: transparent !important;

  &:hover {
    background-color: rgba($primary, 0.12) !important;
    color: $primary !important;
  }
}

.shadow {
  box-shadow: 0 2px 4px 0 rgba(45, 65, 90, 0.22) !important;
}

input,
select {
  font-size: 14px !important;

  &::placeholder {
    font-size: 14px !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #ced4da !important;
  }
}

button,
.dropdown-item {
  font-size: 14px !important;
}

.btn-normal {
  border: solid 1px #cfd8dc;
  background-image: linear-gradient(to bottom, #f4f4f4 14%, #fff);
  font-size: 14px;
  color: #65707b;

  &:hover {
    background-image: none;
    background-color: #ebeef0 !important;
    color: #65707b !important;
  }
}

.bg-primary-light {
  background-color: #ecf7fd !important;
}

.w-content {
  width: fit-content !important;
}

.bg-primary-light {
  background-color: rgba($primary, 0.2) !important;
}

.bg-warning-light {
  background-color: rgba($warning, 0.2) !important;
}

.bg-success-light {
  background-color: rgba($success, 0.2) !important;
}

.bg-danger-light {
  background-color: rgba($danger, 0.2) !important;
}

.rdrCalendarWrapper .rdrMonth {
  margin: 0 !important;
}

.rdrCalendarWrapper .rdrMonth {
  padding: 0 0.5rem !important;
}

.overlay-container {
  display: none;
}

.small-mazaj-logo {
  display: none;
}

.menu-item-label {
  transition: all 0ms;
}

body.overlay {
  .overlay-container {
    position: absolute;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.48;
    z-index: 100;
    display: block;
    top: 0;
    left: 0;
    margin: auto;
  }
}

.brand-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-inline-start: 31px;
  padding-block: 20px;
  .brand-logo-container {
    width: 52px;
    height: 52px;
    .brand-logo {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .brand-name {
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-inline-start: 15px;
    width: 165px;
  }
}
body.min-side-menu {
  .brand-container {
    padding-inline-start: 0px;
    .brand-name {
      display: none;
    }

    .brand-logo-container {
      width: 61px;
      height: 61px;
    }
  }

  .large-mazaj-logo {
    display: none;
  }

  .small-mazaj-logo {
    display: block;
  }

  .app-side-menu {
    width: 82px;
    min-width: 82px;
    max-width: 82px;
  }

  .app-side-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-item-label {
    display: none;
  }

  .main-container {
    width: calc(100vw - 82px);
    max-width: calc(100vw - 82px);
  }
}

.rdrMonthName {
  margin: 25px 0 0 0;
}

.small-flag {
  width: 30px;
  object-fit: contain;
  min-height: 20px;
  margin-inline-end: 10px;
}

.collapse-container {
  min-height: 83px;
  padding: 32px 27px;
  border-radius: 5px;
  box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.2);
  background: #fff;
  margin: 16px 0;

  .collapse-arrow {
    padding: 0 10px;
  }

  .collapse-arrow-icon {
    transform: rotate(0.5turn);
    transition: all 0.5s ease-in-out;
  }

  .collapse-body {
    transition: all 0.3s ease-in;
    height: 100%;
    .container {
      padding: 20px 0 0;
    }
  }

  .collapse-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .collapse-title {
      font-size: 18px;
      font-weight: bolder;
      display: flex;
      align-items: center;
      &__subtitle {
        font-size: 12px;
        font-style: italic;
        margin: 0px 12px;
      }
    }
  }
  &.collapsed {
    .collapse-arrow-icon {
      transform: rotate(0turn);
    }

    .collapse-body {
      height: 0;
      overflow: hidden;
    }
  }
}
input.app_slider[type="checkbox"][disabled] + label {
  opacity: 0.5;
}

.pointer-none {
  pointer-events: none;
}

.small-flag {
  width: 30px;
  object-fit: contain;
  min-height: 20px;
  margin-inline-end: 10px;
}

[data-rbd-draggable-id][style]:first-child {
  background: #fff;
  padding: 10px;
}

.drag-handle {
  position: relative;
  cursor: auto !important;
  .drag-hand {
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
    width: 24px;
    height: 18px;
    cursor: all-scroll;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }

  &.no-drag {
    cursor: auto !important;
    .drag-hand {
      cursor: auto !important;
    }
  }
}

.btn-outline-danger:hover {
  color: #fff;
  opacity: 0.7;
}

.rotate-180 {
  transform: rotate(180deg);
}

.right-0 {
  right: 0;
}
.btn-purple {
  background: #3f45d2;
  color: #fff;
  &:hover {
    color: #fff;
    background-color: #3f45d2 !important;
    border-color: #3f45d2 !important;
    opacity: 0.7;
  }
}

.table-row-hand {
  cursor: move;
  pointer-events: none;
}
.text-xs {
  font-size: 12px;
  line-height: 1.2;
}
.text-sm {
  font-size: 14px;
  line-height: 1.3;
}
.text-md {
  font-size: 15px;
  line-height: 1.4;
}
.text-lg {
  font-size: 16px;
  line-height: 1.5;
}
